const sliderItems = document.querySelectorAll('.slider-item');

sliderItems.forEach((item) => {
  item.classList.remove('slider-bounce');
});

const observer = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      sliderItems.forEach((item) => {
        item.classList.add('slider-bounce');
      });

      return;
    }

    sliderItems.forEach((item) => {
      item.classList.remove('slider-bounce');
    });
  });
});

observer.observe(document.querySelector('.grad-bl-tr-light'));
